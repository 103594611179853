<template>
  <dok-downdrop>
    <template v-slot:button>
      <div class="relative">
        <div
          style="
            width: 10px;
            height: 10px;
            background: #fd5b68;
            right: -2px;
            top: -2px;
          "
          v-show="appointments.length"
          class="absolute rounded-full"
        ></div>
        <img src="/svg/calendar.svg" alt="demandes" />
      </div>
    </template>

    <template v-slot:content>
      <div class="flex justify-between mb-6">
        <h3 class="text-xl font-EffraR text-blackedsidebar">Demandes:</h3>
      </div>
      <div class="flex flex-col gap-y-4" v-if="appointments.length">
        <template v-if="!loading">
          <div
            v-for="(appointment, i) in appointments"
            :key="i"
            class="
            border-2 border-solid border-gray-300
            rounded-lg
            p-3
            flex flex-col
            gap-y-4
          "
          >
            <div class="flex flex-row items-start gap-x-3">
              <img
                :src="
                  appointment.type === 'VIDEO-CONSULTATION'
                    ? '/svg/videocons.svg'
                    : '/svg/agendacira.svg'
                "
              />
              <div class="flex flex-col gap-y-3 w-full">
                <div class="flex flex-row gap-x-1">
                  <div class="flex flex-row gap-x-2 w-full">
                    <img src="/svg/calendar_sm.svg" />
                    <span class="font-EffraR text-base">{{
                      _moment()(appointment.startAt)
                        .format("DD MMMM")
                        .toUpperCase()
                    }}</span>
                  </div>
                  <div class="flex flex-row gap-x-2 w-full">
                    <img src="/svg/clock_sm.svg" />
                    <span class="font-EffraR text-base">{{
                      _moment()(appointment.startAt)
                        .format("HH:mm")
                        .toUpperCase()
                    }}</span>
                  </div>
                </div>
                <div id="fullname" class="text-lg font-EffraM">
                  {{ appointment.patient_firstName }}
                  {{ appointment.patient_lastName }}
                </div>
                <div
                  id="fullname"
                  class="text-base font-EffraM text-reddok"
                  v-if="appointment.transferedBy"
                >
                  Transfered by {{ appointment.transferedBy }}
                </div>
                <span
                  v-if="appointment.categoryId"
                  class="
                  border border-solid
                  rounded-full
                  font-EffraR
                  text-base
                  py-1
                  px-3
                "
                  :class="
                    appointment.type === 'VIDEO-CONSULTATION'
                      ? 'border-indigo-700 text-indigo-700'
                      : 'border-dokBlue-ultra text-dokBlue-ultra'
                  "
                >
                  {{ appointment.category_title }}
                </span>
              </div>
            </div>

            <div class="flex justify-between gap-x-1">
              <div class="flex-1">
                <button
                  @click="setAppointmentStatus(i, appointment._id, 'REJECTED')"
                  class="
                  bg-transparent
                  border-0
                  text-red-600
                  font-EffraR
                  text-base
                  cursor-pointer
                "
                >
                  Refuser
                </button>
              </div>
              <!-- <div class="flex-1" v-if="!appointment.parent">
                <button
                  @click="onOwnerChange(i, appointment._id)"
                  class="
                  bg-transparent
                  border-0
                  text-blue-600
                  font-EffraR
                  text-base
                  cursor-pointer
                "
                  :class="
                    appointment.status === 'OWNER-TRANSFERED'
                      ? 'line-through'
                      : ''
                  "
                >
                  Transfer
                </button>
              </div> -->
              <div class="flex flex-row gap-x-5">
                <button
                  class="
                  flex flex-row
                  items-center
                  gap-x-1
                  bg-transparent
                  border-0
                  text-gray-600
                  font-EffraR
                  text-base
                  cursor-pointer
                "
                  v-if="appointment.status !== 'TRANSFERED'"
                  @click="onProposeClicked(appointment)"
                >
                  Changer l’horaire
                </button>
                <button
                  @click="setAppointmentStatus(i, appointment._id, 'ACCEPTED')"
                  class="
                  flex flex-row
                  items-center
                  gap-x-1
                  bg-transparent
                  border-0
                  text-green-600
                  font-EffraR
                  text-base
                  cursor-pointer
                "
                >
                  Accepter
                </button>
              </div>
            </div>
          </div>

          <dok-button
            size="md"
            @click.native="onGetNewAppointments(true)"
            v-if="loadMore"
            >Voir plus</dok-button
          >
        </template>

        <template v-if="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="
            margin: auto;
            background: #fff;
            display: block;
            shape-rendering: auto;"
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
            </circle>
            <circle cx="70" cy="50" fill="#2e6bff" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="0s"
              ></animate>
            </circle>
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
              <animate
                attributeName="fill-opacity"
                values="0;0;1;1"
                calcMode="discrete"
                keyTimes="0;0.499;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animate>
            </circle>
          </svg>
        </template>
      </div>
      <div
        class="flex flex-col gap-y-2 items-center justify-center"
        v-if="!appointments.length"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="83"
          height="82"
          fill="none"
          viewBox="0 0 83 82"
        >
          <path
            stroke="#DADADA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="10"
            d="M65.808 13.667H17.974c-3.774 0-6.833 3.06-6.833 6.833v47.834c0 3.773 3.059 6.833 6.833 6.833h47.834c3.773 0 6.833-3.06 6.833-6.833V20.5c0-3.774-3.06-6.833-6.834-6.833zM55.557 6.833V20.5M28.225 6.833V20.5M11.14 34.167h61.5"
          />
        </svg>

        <span style="color: #585858" class="text-base font-EffraR"
          >Pas de demande de rendez-vous</span
        >
      </div>
      <!-- Transfer modal -->
      <modal
        :isActive="transferEventIndex !== -1"
        :title="`Adresser le patient à un confrère`"
        :exitModal="
          () => {
            transferEventIndex = -1;
            selectedDoctorId = null;
          }
        "
        :width="500"
      >
        <div class="px-5 flex flex-col gap-y-2">
          <dok-input
            size="lg"
            d-placeholder="Rechercher..."
            :d-model.sync="search"
          >
            <template v-slot:rightIcon>
              <i class="fas fa-search"></i>
            </template>
          </dok-input>

          <select
            v-if="doctors"
            class="
             border-0 w-full bg-gray-100 rounded-b font-EffraR shadow
            "
            multiple
          >
            <option
              v-for="doctor in doctors"
              :key="doctor._id"
              class="px-1 py-2"
              @click="
                () => {
                  selectedDoctorId = doctor._id;
                }
              "
            >
              {{ `${doctor.firstName} ${doctor.lastName}` }}
            </option>
          </select>

          <dok-button
            size="md"
            custom-class="w-full rounded-none"
            @click.native="onTransfer"
            >Transfer</dok-button
          >
        </div>
      </modal>
      <!-- //Transfer modal -->
    </template>
  </dok-downdrop>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import moment from "moment";
import { EVENTS_SUBSCRIBE } from "@/graphql/notifications";
const modal = () => import("@/views/global-components/dragModal");
import { debounce } from "lodash";
export default {
  components: { modal },
  data() {
    return {
      drop: false,
      appointments: [],
      counterNotification: localStorage.getItem("AppointmentsCounter"),
      paginate: { page: 1, count: 0 },
      loading: false,
      loadMore: true,
      modalis: false,
      transferEventIndex: -1,
      selectedDoctorId: null,
      search: null,
      doctors: []
    };
  },
  watch: {
    search: debounce(function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.listDoctors();
      }
    }, 500)
  },
  methods: {
    ...mapActions("agenda", [
      "INDEX_PENDING_EVENTS",
      "UPDATE_STATUS_EVENT",
      "TRANSFER_EVENT"
    ]),
    ...mapActions({ getDoctors: "user/GET_ALL_DOCTORS" }),
    _moment() {
      return moment;
    },
    onProposeClicked(event) {
      if (event._id) {
        this.drop = false;
        EventBus.$emit("Close_dropdown");
        EventBus.$emit("ProposEvent", event);
      }
    },
    async onDrop() {
      this.drop = !this.drop;

      let notificationUpdate = JSON.parse(this.counterNotification);
      notificationUpdate.counter = 0;

      localStorage.setItem(
        "AppointmentsCounter",
        JSON.stringify(notificationUpdate)
      );

      this.$forceUpdate();
    },
    async onGetNewAppointments(loadMore = false) {
      if (loadMore) {
        this.paginate.page++;
        this.loading = true;
      }

      const { ok, data, count } = await this.INDEX_PENDING_EVENTS({
        page: this.paginate.page
      });

      if (ok) {
        if (!data.length) {
          this.loadMore = false;
        }

        this.appointments = loadMore ? [...this.appointments, ...data] : data;
      }

      this.loading = false;
    },
    setAppointmentStatus: async function(index, appointmentId, status) {
      const { ok, data, message } = await this.UPDATE_STATUS_EVENT({
        _id: appointmentId,
        status
      });

      if (ok) {
        if (status === "ACCEPTED") {
          EventBus.$emit("Push-New-Event-Agenda", data);
        }
        this.appointments.splice(index, 1);
        this.drop = false;
      } else {
        return EventBus.$emit("flashDok", {
          timerAlert: 5000,
          type: "errorr",
          message: message
        });
      }
    },
    onOwnerChange: async function(index) {
      let appointment = this.appointments[index];
      if (appointment.status === "OWNER-TRANSFERED") {
        return;
      }
      this.listDoctors();
      this.transferEventIndex = index;
    },

    async onTransfer() {
      if (!this.selectedDoctorId) {
        return alert("selectioner an doctor");
      }
      this.$vs.loading();
      const { ok } = await this.TRANSFER_EVENT({
        doctorId: this.selectedDoctorId,
        eventId: this.appointments[this.transferEventIndex]._id
      });
      this.$vs.loading.close();
      if (ok) {
        this.appointments.splice(this.transferEventIndex, 1);
        this.transferEventIndex = -1;
        this.selectedDoctorId = null;
      }
    },
    listDoctors() {
      this.$vs.loading();
      this.getDoctors({
        params: { term: this.search },
        onData: ({ ok, data }) => {
          if (ok) {
            this.doctors = data;
          }

          this.$vs.loading.close();
        }
      });
    },
    plusNotifications() {
      if (!this.counterNotification) {
        return localStorage.setItem(
          "AppointmentsCounter",
          JSON.stringify({ counter: 1 })
        );
      }

      let notificationUpdate = JSON.parse(this.counterNotification);
      notificationUpdate.counter++;

      localStorage.setItem(
        "AppointmentsCounter",
        JSON.stringify(notificationUpdate)
      );

      this.$forceUpdate();
    }
  },
  mounted() {
    this.onGetNewAppointments();

    EventBus.$on("New_Appointment_From_Patient", async data => {
      this.onGetNewAppointments();
      this.plusNotifications();

      await new Howl({
        src: `/audios/notification.mp3`,
        autoplay: true,
        volume: 0.5
      });
    });
  },
  apollo: {
    $subscribe: {
      appointments: {
        query: EVENTS_SUBSCRIBE,
        variables() {
          return {
            userId: this.$auth.getUserId()
          };
        },
        result({ data }) {
          this.onGetNewAppointments();
        }
      }
    }
  }
};
</script>
