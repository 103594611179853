import gql from "graphql-tag";
export const NOTIFICATIONS_SUBSCRIBE = gql`
  subscription notificationsListener($userId: String = "") {
    live_events(
      where: { userId: { _eq: $userId } }
      limit: 1
      order_by: { created_at: desc }
    ) {
      id
      model
      model_id
    }
  }
`;
export const NOTIFICATIONS_UNREAD_SUBSCRIBE = gql`
  subscription countUnreadNotifications($status: String = "unread") {
    notifications_aggregate(where: { status: { _eq: $status } }) {
      aggregate {
        count
      }
    }
  }
`;
export const EVENTS_SUBSCRIBE = gql`
  subscription pendingAppointements($userId: String = "") {
    pending_appointements(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      consultation_id
      consultation_motif
      created_at
      id
      patient_firstname
      patient_lastname
      start_at
      status
      updated_at
      user_id
    }
  }
`;

export const NOTIFICATIONS_MARK_AS_READ = gql`
  mutation markAsRead($uuid: uuid! = "") {
    update_notifications_by_pk(
      pk_columns: { id: $uuid }
      _set: { status: "read" }
    ) {
      status
    }
  }
`;
